import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import {} from 'googlemaps';
import { AgmCoreModule, MapsAPILoader } from "@agm/core";
// import { TravelMode } from 'googlemaps';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {
    title = 'ruvenCalcApp';
    currentStepId = 0;
    distance: number;
    loadingModels = false;

    makes = [];
    models = [];

    formData = {
        from: null,
        to: null,
        date: '',
        make: '',
        model: '',
        year: '',
        running: 'yes',
        forklift: 'no',
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    };

    source = null;
    destination = null;

    calculatedPrice = {
        basePrice: 0,
        flatCharges: 0,
        multiplier: 0,
        distance: 0,
        totalPrice: 0,
    };

    contactRequired = '';
    error = '';

    steps = [
        'Маршрут',
        'Информация',
        'Вашите координати'
    ];

    // apiBaseUrl = 'http://ruven.local/wp-content/themes/ruven_ram/api';
    apiBaseUrl = 'http://ruven.dizitnow.com/wp-content/themes/ruven_ram/api';

    constructor(
        private http: HttpClient
    ) {
        this.http.get(`${this.apiBaseUrl}/cars.php`).subscribe((response: any) => {
            this.makes = response;
            this.makes.unshift({id: -1, name: 'друга'});
        });
    }

    modelDisplayName() {
        return this.formData.model
            ? this.models.find((item) => item.id === this.formData.model).name
            : '';
    }

    makeDisplayName() {
        return this.formData.make
            ? this.makes.find((item) => item.id === this.formData.make).name
            : '';
    }

    onPrevClicked() {
        this.error = '';
        this.contactRequired = '';

        this.currentStepId = (this.currentStepId - 1) % 4;
    }
    onNextClicked() {
        if (this.currentStepId === 2) {
            this.calculate();
        }
        this.currentStepId = (this.currentStepId + 1) % 4;
    }

    onLocationSelected(endpoint, place) {
        if (!this.formData.from || !this.formData.to) {
            return;
        }

        if (this.formData.from.geometry && this.formData.to.geometry) {

            this.source = {
                country: this.getCountryFromGoogleAddress(this.formData.from),
                province: this.getProvinceFromGoogleAddress(this.formData.from),
                lat: this.formData.from.geometry.location.lat(),
                lng: this.formData.from.geometry.location.lng()
            };

            this.destination = {
                country: this.getCountryFromGoogleAddress(this.formData.to),
                province: this.getProvinceFromGoogleAddress(this.formData.to),
                lat: this.formData.to.geometry.location.lat(),
                lng: this.formData.to.geometry.location.lng()
            };
        }
    }

    getCountryFromGoogleAddress(googleAddressObject)
    {
        return googleAddressObject.address_components.find((item) => item.types.includes('country')).short_name;
    }

    getProvinceFromGoogleAddress(googleAddressObject)
    {
        return googleAddressObject.address_components.find((item) => item.types.includes('administrative_area_level_1')).long_name;
    }

    onMakeChange() {
        this.formData.model = '';
        this.models = [];
        this.loadingModels = true;

        if (parseInt(this.formData.make, 10) > 0) {
            this.http.get(`${this.apiBaseUrl}/cars.php?make=${this.formData.make}`).subscribe((response: any) => {
                this.models = response;
                this.models.unshift({id: -1, name: 'друг'});
                this.loadingModels = false;
            });
        } else {
            this.models = [
                {id: -1, name: 'друг'},
            ];
            this.loadingModels = false;
        }
    }

    calculate() {
        this.error = '';
        this.contactRequired = '';

        this.http.post(`${this.apiBaseUrl}/calculate.php`, {
            source: this.source,
            destination: this.destination,
            date: this.formData.date,
            model: this.formData.model,
            year: this.formData.year,
            running: this.formData.running,
            forklift: this.formData.forklift,
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            email: this.formData.email,
            phone: this.formData.phone,
        }).subscribe((response: any) => {
            console.log('>>>debugger info', response.debug);

            if (response.error) {
                this.error = response.error;
            } else if (response.contactRequired) {
                this.contactRequired = 'Ще се свържем с Вас за допълнителна информация, за да можем да калкулираме правилно цената.';
            } else {
                this.calculatedPrice = response;
            }
        });
    }

    onResetClicked() {
        this.formData = {
            from: null,
            to: null,
            date: '',
            make: '',
            model: '',
            year: '',
            running: '',
            forklift: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
        };

        this.source = {};
        this.destination = {};

        this.calculatedPrice = {
            basePrice: 0,
            flatCharges: 0,
            multiplier: 0,
            distance: 0,
            totalPrice: 0,
        };

        this.error = '';
        this.contactRequired = '';

        this.currentStepId = 0;
    }
}
