<ul id="steps" class="steps" *ngIf="currentStepId !== 3">
    <li *ngFor="let step of steps; index as i" [ngClass]="{'current': i === currentStepId}">0{{ i+1 }} <span>{{ step }}</span></li>
</ul>

<form class="mainForm">
    <div [ngSwitch]="currentStepId">
        <div *ngSwitchCase="0" class="inner-form">
            <div class="left">
                <div class="input-wrap first">
                    <div>
                        <img class="search_icon_image" src="/wp-content/themes/ruven_ram/app/assets/img/map_pin.png" width="24">
                    </div>

                    <div class="input-field first">
                        <label>От</label>
                        <input matInput
                            placeholder="Град, държава"
                            matGoogleMapsAutocomplete
                            name="from"
                            [(ngModel)]="formData.from"
                            (onLocationSelected)="onLocationSelected('from', $event)">
                    </div>
                </div>

                <div class="input-wrap first" style="border-radius: 0px; border-left: 1px;">
                    <div>
                        <img class="search_icon_image" src="/wp-content/themes/ruven_ram/app/assets/img/map_pin.png" width="24">
                    </div>

                    <div class="input-field first">
                        <label>До</label>
                        <input matInput
                            placeholder="Град, държава"
                            matGoogleMapsAutocomplete
                            name="to"
                            [(ngModel)]="formData.to"
                            (onLocationSelected)="onLocationSelected('to', $event)">
                    </div>
                </div>

                <div class="input-wrap first" style="border-radius: 0px; border-left: 1px;">
                    <div class="input-field first">
                        <label>Дата на вземане</label>

                        <input matInput
                            name="on"
                            [(ngModel)]="formData.date"
                            [matDatepicker]="picker"
                        >
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </div>
            </div>

            <button class="btn-search shake" type="button" (click)="onNextClicked()" [disabled]="!formData.from || !formData.to || !formData.date">Напред</button>
        </div>

        <div *ngSwitchCase="1" class="inner-form inner-form--s2">
            <div class="left">
                <div class="input-wrap first input-wrap--s2-f1" >
                    <div class="input-field first">
                        <label>Година</label>
                        <input matInput
                            name="year"
                            [(ngModel)]="formData.year"
                        >
                    </div>
                </div>
                <div class="input-wrap first input-wrap--s2-f2">
                    <div class="input-field first">
                        <label>Марка</label>

                        <mat-form-field appearance="fill">
                            <mat-select
                                name="make"
                                [(ngModel)]="formData.make"
                                (selectionChange)="onMakeChange()"
                            >
                              <mat-option *ngFor="let make of makes" [value]="make.id">
                                {{make.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="input-wrap first input-wrap--s2-f3">
                    <div class="input-field first">
                        <label>Модел</label>

                        <mat-spinner *ngIf="loadingModels" color="#1D5AF6" diameter="20" style="margin-top: 7px; float: right;"></mat-spinner>

                        <mat-form-field appearance="fill" [ngStyle]="{ visibility: models.length ? 'visible' : 'hidden' }">
                            <mat-select [(ngModel)]="formData.model" name="model">
                              <mat-option *ngFor="let model of models" [value]="model.id">
                                {{model.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="left">
                <div class="input-wrap first input-wrap--s2-f4">
                    <div class="input-field first">
                        <label>В движение</label>

                        <mat-radio-group [(ngModel)]="formData.running" aria-label="Select an option" name="running">
                            <mat-radio-button [value]="'yes'">Да</mat-radio-button>
                            <mat-radio-button [value]="'no'">Не</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="input-field first">
                        <label>Forklift</label>

                        <mat-radio-group [(ngModel)]="formData.forklift" aria-label="Select an option" name="forklift">
                            <mat-radio-button [value]="'yes'">Да</mat-radio-button>
                            <mat-radio-button [value]="'no'">Не</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>


            <button class="btn-search btn--outline shake" type="button" (click)="onPrevClicked()">Назад</button>
            <button class="btn-search shake" type="button" (click)="onNextClicked()" [disabled]="!formData.year || !formData.make || !formData.model || !formData.running">Напред</button>
        </div>

        <div *ngSwitchCase="2" class="inner-form inner-form--s3">
            <div class="left">
                <div class="input-wrap first input-wrap--s3-f1">
                    <div class="input-field first">
                        <label>Име</label>
                        <input matInput
                            name="first_name"
                            [(ngModel)]="formData.first_name"
                        >
                    </div>
                </div>
                <div class="input-wrap first input-wrap--s3-f2">
                    <div class="input-field first">
                        <label>Фамилия</label>
                        <input matInput
                            name="last_name"
                            [(ngModel)]="formData.last_name"
                        >
                    </div>
                </div>
            </div>

            <div class="left">
                <div class="input-wrap first input-wrap--s3-f3">
                    <div class="input-field first">
                        <label>Имейл</label>
                        <input matInput
                            name="email"
                            [(ngModel)]="formData.email"
                        >
                    </div>
                </div>
                <div class="input-wrap first input-wrap--s3-f4">
                    <div class="input-field first">
                        <label>Телефон</label>
                        <input matInput
                            name="phone"
                            [(ngModel)]="formData.phone"
                        >
                    </div>
                </div>
            </div>

            <button class="btn-search btn--outline shake" type="button" (click)="onPrevClicked()">Назад</button>
            <button class="btn-search shake" type="button" (click)="onNextClicked()">Калкулирай</button>
        </div>

        <!-- <div *ngSwitchCase="3" class="inner-form inner-form--s4"> -->


        {{ distance}}
        <div class="inner-form inner-form--s4">
            <div class="row">
                <div class="col text-right">
                    От:
                </div>
                <div class="col">
                    {{ formData.from && formData.from.formatted_address }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    До:
                </div>
                <div class="col">
                    {{ formData.to && formData.to.formatted_address }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    На:
                </div>
                <div class="col">
                    {{ formData.date | date : 'dd/MM/yyyy' }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    Година:
                </div>
                <div class="col">
                    {{ formData.year }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    Марка:
                </div>
                <div class="col">
                    {{ makeDisplayName() }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    Модел:
                </div>
                <div class="col">
                    {{ modelDisplayName() }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    В движение:
                </div>
                <div class="col">
                    {{ formData.running === 'yes' ? 'да' : 'не' }}
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    Forklift:
                </div>
                <div class="col">
                    {{ formData.forklift === 'yes' ? 'да' : 'не' }}
                </div>
            </div>

            <div *ngIf="error" class="alert alert-danger">
                <div class="row">
                    <div class="col">{{ error }}</div>
                </div>
            </div>

            <div *ngIf="contactRequired" class="alert alert-warning">
                <div class="row">
                    <div class="col">{{ contactRequired }}</div>
                </div>
            </div>

            <div *ngIf="calculatedPrice.totalPrice">
                <div class="row">
                    <div class="col text-right">Разстояние:</div>
                    <div class="col">{{ calculatedPrice.distance }} km.</div>
                </div>
                <div class="row">
                    <div class="col text-right">Крайна цена:</div>
                    <div class="col">{{ calculatedPrice.totalPrice }} EUR</div>
                </div>
            </div>

            <div class="row">
                <div class="col text-right">
                    <button class="btn-search btn--outline shake" style="border-radius: 4px;" type="button" (click)="onPrevClicked()">Назад</button>
                </div>
                <div class="col">
                    <button class="btn-search btn--outline shake" style="border-radius: 4px;" type="button" (click)="onResetClicked()">Нова калкулация</button>
                </div>
            </div>

        </div>
    </div>

    <!-- <button type="button" (click)="onNextClicked()">Напред</button> -->

</form>

<!-- <input id="your-car" type="hidden" class="choices__item" data-value="mock" value="mock" /> -->
